﻿a {
    color: rgb(3,155,229);
    text-decoration: none;
}


html{
    font-size: 62.5%;
    line-height: 1.4;
    letter-spacing: -.1px;
    font-family: Roboto,"Helvetica Neue",sans-serif;
}

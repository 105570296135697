/*****************************************************
/* VENDORS
/*****************************************************/

// BOOTSTRAP
//@import "vendor/bootstrap/_bootstrap.scss";
//@import "public/Fullpage/jquery.fullPage.scss";

// Cross-browser reset by Nicolas Gallagher (http://necolas.github.io/normalize.css/)
//@import "base/_normalize.scss";



/*****************************************************
/* PAGES
/*****************************************************/
//@import "public/pages/_commercial.scss";
//@import "public/pages/_style.scss";
//@import "public/pages/_animate.scss";
@import "public/pages/_login.scss";
//@import "public/pages/_register.scss";
@import "public/pages/_general.scss";
@import "public/pages/_forgotpwd.scss";
@import "public/pages/_resetpwd.scss";
@import "public/pages/_confirmemail.scss";
@import "public/pages/_externallogin.scss";
@import "public/pages/helpers.scss";
﻿#forgot-password, #forgot-password-confirmation {
    height: 100%;
    background: url('/assets/images/backgrounds/Login6.jpg') no-repeat;
    background-size: cover;

    #forgot-password-form-wrapper, #forgot-password-confirmation-form-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        #forgot-password-form, #forgot-password-confirmation-form {
            width: 384px;
            max-width: 384px;
            padding: 32px;
            text-align: center;
            background: #FFFFFF;

            .logo {
                width: auto;
                height: auto;
                margin: 32px auto;
            }

            .title {
                font-size: 17px;
                margin: 16px 0 32px 0;
            }

            form {
                width: 100%;
                text-align: left;

                .submit-button {
                    width: 220px;
                    margin: 16px auto;
                    display: block;
                }
            }

            .login {
                margin: 32px auto 24px auto;
                width: 250px;
                font-weight: 500;

                .text {
                    margin-right: 8px;
                }

                .link {

                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: 100%) {

    #forgot-password, #forgot-password-confirmation {

        #forgot-password-form-wrapper, #forgot-password-confirmation-form-wrapper {
            padding: 16px;

            #forgot-password-form, #forgot-password-confirmation-form {
                padding: 24px;
                width: 100%;

                form {

                    .md-button {
                        width: 90%;
                    }
                }
            }
        }
    }

}